import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-incrementador',
  templateUrl: './incrementador.component.html',
  styles: []
})
export class IncrementadorComponent implements OnInit {

  @Input('nombre')  leyenda: string = 'Leyenda';
  @Input() progreso: number = 50;
  @Output() cambioValor: EventEmitter<number> = new EventEmitter();


  constructor() {
    // console.log('leyenda', this.leyenda);
    // console.log('progreso', this.progreso);
  }

  ngOnInit() {
    //console.log('leyenda', this.leyenda);
    //console.log('progreso', this.progreso);
  }

  cambiarValor(valor){
    
    if( this.progreso >= 100){
      return;
    }

    if( this.progreso <= 0){
      return;
    }

    this.progreso = this.progreso + valor;

    this.cambioValor.emit ( this.progreso );
  }

}
