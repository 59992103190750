import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DatosWtsService } from '../../services/wts/datos-wts.service';

var ln = navigator.language;

var msg1,msg2,msg3,msg4,msg5,msg6,msg7
var msg8,msg9,msg10



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponentClient implements OnInit {
  title = "app";
  chart = [];
  loadAPI: Promise<any>;
  constructor(private tiposWtsReparacion: DatosWtsService) {    
    this.loadScripts();
    Chart.plugins.unregister(ChartDataLabels);
    
  }  

  ngOnInit() {
    

    if ((ln == 'en-EN') || (ln == 'en')) {
      msg1="Current WTS under repair by Installation";
      msg2="WTS Repair History by Installation";
      msg3="Current WTS under repair by Workshop";
      msg4="WTS repair history by Workshop";
      msg5="Current WTS in Repair by State";
      msg6="WTS Repair History by Cost";
      msg7="WTS by Factoria";
      msg8="WTS Annual Repair History";
      msg9="Historical WTS Repair Ratio";
      msg10="WTS repairs from the last 14 months";
      document.getElementById("SPTecnico").innerHTML = "WTS In Technical Support";
      document.getElementById("LEntrega").innerHTML = "WTS Ready to Deliver";
      document.getElementById("enrepara").innerHTML = "WTS Under Repair ...";
      document.getElementById("enproceso").innerHTML = "WTS In Process ...";
      document.getElementById("enespera").innerHTML = "WTS on hold ...";
      document.getElementById("utrimes").innerHTML = "WTS Last Quarter";
      document.getElementById("titulo_Pagina").innerHTML = "Control Board";
      
     
    } else if ((ln == 'es') || (ln == 'es-ES')) {
      msg1="WTS actuales en reparación por Instalacion";
      msg2="Historial de reparaciones Wts por Instalacion";
      msg3="WTS actuales en reparacion por Taller";
      msg4="Historial de reparaciones WTS por Taller";
      msg5="WTS actuales en reparacion por Estado";
      msg6="Historico de reparaciones WTS por Coste";
      msg7="WTS por Factoria";
      msg8="Historico de reparaciones anual de WTS";
      msg9="Relación histórica de reparación de WTS";
      msg10="Reparaciones WTS de los ultimos 14 meses";
      document.getElementById("titulo_Pagina").innerHTML = "Tablero Control";
      
     
    
    } else if ((ln == 'de') || (ln == 'de-DE')) {
      msg1="Aktuelle WTS, die durch Installation repariert werden";
      msg2="WTS Reparaturverlauf durch Installation";
      msg3="Aktuelle WTS in Reparatur durch Werkstatt";
      msg4="WTS-Reparaturverlauf von Werkstatt";
      msg5="Aktuelle Wachtturm in Reparatur nach Staat";
      msg6="WTS-Reparaturverlauf nach Kosten";
      msg7="WTS von Fabrik";
      msg8="WTS Jährliche Reparaturhistorie";
      msg9="Historisches WTS-Reparaturverhältnis";
      msg10="WTS-Reparaturen der letzten 14 Monate";
      document.getElementById("SPTecnico").innerHTML = "WTS im technischen Support";
      document.getElementById("LEntrega").innerHTML = "WTS bereit zu liefern";
      document.getElementById("enrepara").innerHTML = "WTS in Reparatur ...";
      document.getElementById("enproceso").innerHTML = "WTS in Bearbeitung ...";
      document.getElementById("enespera").innerHTML = "WTS in Wartestellung ...";
      document.getElementById("utrimes").innerHTML = "WTS im letzten Quartal";
      document.getElementById("titulo_Pagina").innerHTML = "Steuerplatine";
      

    } else if ((ln == 'ca')|| (ln == 'ca-ES')) {
      msg1="WTS actuals que es reparen per instal·lació";
      msg2="Històric de reparacions WTS per Instalacion";
      msg3="WTS actuals en reparació per Taller";
      msg4="Històric de reparacions WTS per Taller";
      msg5="WTS actual en reparació per estat";
      msg6="Historial de reparacions de WTS per cost";
      msg7="WTS per factoria";
      msg8="Historial anual de reparacions de WTS";
      msg9="Relació històrica de reparació de WTS";
      msg10="Reparacions de WTS dels darrers 14 mesos";
      document.getElementById("SPTecnico").innerHTML = "WTS en suport tècnic";
      document.getElementById("LEntrega").innerHTML = "WTS llest per lliurar";
      document.getElementById("enrepara").innerHTML = "WTS en reparació ...";
      document.getElementById("enproceso").innerHTML = "WTS en procés ...";
      document.getElementById("enespera").innerHTML = "WTS en espera ...";
      document.getElementById("utrimes").innerHTML = "WTS Últim trimestre";
      document.getElementById("titulo_Pagina").innerHTML = "Tauler de control";
      

    } else if ((ln == 'pt-PT') || (ln == 'pt-BR')||(ln == 'pt')) {
      msg1="WTS atuais que são reparados pela instalação";
      msg2="Histórico de reparos WTS por instalação";
      msg3="WTS atual em reparo pela oficina";
      msg4="Histórico de reparos WTS por Oficina";
      msg5="WTS atual em reparo por estado";
      msg6="Histórico de reparos WTS por custo";
      msg7="WTS por Fábrica";
      msg8="Histórico de reparos anuais WTS";
      msg9="Razão de reparo WTS histórica";
      msg10="Reparos WTS dos últimos 14 meses";
      document.getElementById("SPTecnico").innerHTML = "WTS em suporte técnico";
      document.getElementById("LEntrega").innerHTML = "WTS pronto para entregar";
      document.getElementById("enrepara").innerHTML = "WTS em reparo ...";
      document.getElementById("enproceso").innerHTML = "WTS em processo ...";
      document.getElementById("enespera").innerHTML = "WTS em espera ...";
      document.getElementById("utrimes").innerHTML = "WTS Último Trimestre";
      document.getElementById("titulo_Pagina").innerHTML = "Painel de controle";
      
      
    } else if ((ln == 'fr-FR') || (ln == 'fr')) {
      msg1="WTS actuel qui sont réparés par l'installation";
      msg2="Historique des réparations WTS par installation";
      msg3="WTS actuel en réparation par l'atelier";
      msg4="Historique des réparations WTS par Atelier";
      msg5="WTS actuel en réparation par état";
      msg6="Historique des réparations WTS par coût";
      msg7="WTS par usine";
      msg8="Historique des réparations annuelles WTS";
      msg9="Rapport de réparation historique du WTS";
      msg10="Réparations WTS des 14 derniers mois";
      document.getElementById("SPTecnico").innerHTML = "WTS dans le support technique";
      document.getElementById("LEntrega").innerHTML = "WTS prêt à livrer";
      document.getElementById("enrepara").innerHTML = "WTS en réparation ...";
      document.getElementById("enproceso").innerHTML = "WTS en cours ...";
      document.getElementById("enespera").innerHTML = "WTS en attente ...";
      document.getElementById("utrimes").innerHTML = "WTS dernier trimestre";
      document.getElementById("titulo_Pagina").innerHTML = "Tableau de contrôle";
      
    } else{
      msg1="Current WTS under repair by Installation";
      msg2="WTS Repair History by Installation";
      msg3="Current WTS under repair by Workshop";
      msg4="WTS repair history by Workshop";
      msg5="Current WTS in Repair by State";
      msg6="WTS Repair History by Cost";
      msg7="WTS by Factoria";
      msg8="WTS Annual Repair History";
      msg9="Historical WTS Repair Ratio";
      msg10="WTS repairs from the last 14 months";
      document.getElementById("SPTecnico").innerHTML = "WTS In Technical Support";
      document.getElementById("LEntrega").innerHTML = "WTS Ready to Deliver";
      document.getElementById("enrepara").innerHTML = "WTS Under Repair ...";
      document.getElementById("enproceso").innerHTML = "WTS In Process ...";
      document.getElementById("enespera").innerHTML = "WTS on hold ...";
      document.getElementById("utrimes").innerHTML = "WTS Last Quarter";
      document.getElementById("titulo_Pagina").innerHTML = "Control Board";
    }

    
  //   const changeBackgroundPlugin = {      
  //     beforeDraw:  chart => {
  //       const width = 640;
  //       const height = 290;
  //       const ctx = chart.chart.ctx;
  //       ctx.restore();
  //       const fontSize = 3;
  //       ctx.font = fontSize + "em sans-serif";
  //       ctx.textBaseline = 'middle';        
  //       var total = '22';
  //       const text = total;
  //       const textX = Math.round((width - ctx.measureText(text).width) / 2);
  //       const textY = height / 2;
  //       ctx.fillText(text, textX, textY);
  //       ctx.save();
  //     }
  // };

  // Chart.pluginService.register(changeBackgroundPlugin);

          
    this.tiposWtsReparacion.tiposWtsReparacionCli().subscribe (res => {     
      const instalacion = res['data'].map(res => res.instalacion)
      const numero = res['data'].map(res => res.numero)
    var chart  = new Chart('canvas',{
        type: 'doughnut',
        data: {
          labels: instalacion,
          datasets: [
            {
              data: numero,
              borderColor:[
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'
              ],
              backgroundColor: [
                'rgba(255, 99, 132,.2)',
                'rgba(255, 159, 64,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(153, 102, 255,.2)',
                'rgba(201, 203, 207,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(255, 205, 86,.2)',
                'rgba(75, 192, 192,.2)',
                'rgba(255, 105, 97,.2)',
                'rgba(152, 130, 112,.2)',
                'rgba(253, 247, 194,.2)',
                'rgba(224, 254, 254,.2)',
                'rgba(234, 235, 255,.2)',
                'rgba(182, 77, 58,.2)'              
              ],                   
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,    
          position: 'left',      
        }, 
        title:{
          display:true,
          text:msg1,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },   
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numero.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.60);
          const textY = height / 1.65;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]         
      });            
    });    

    this.tiposWtsReparacion.tiposWtsReparacionHistCli().subscribe (res => {     
      const instalacionHist = res['data'].map(res => res.instalacion)
      const numeroHist = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas10',{
        type: 'doughnut',
        data: {
          labels: instalacionHist,
          datasets: [
            {
              data: numeroHist,    
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'              
              ],          
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,    
          position: 'left',      
        }, 
        title:{
          display:true,
          text:msg2,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numeroHist.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.59);
          const textY = height / 1.75;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]         
      });            
    });

    this.tiposWtsReparacion.tallerWtsReparacionCli().subscribe (res => {      
      const taller = res['data'].map(res => res.taller)
      const numero2 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas2',{
        type: 'doughnut',
        data: {
          labels: taller,
          datasets: [
            {
              data: numero2,
              borderColor:[
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'
              ],
              backgroundColor: [
                'rgba(255, 99, 132,.2)',
                'rgba(255, 159, 64,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(153, 102, 255,.2)',
                'rgba(201, 203, 207,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(255, 205, 86,.2)',
                'rgba(75, 192, 192,.2)',
                'rgba(255, 105, 97,.2)',
                'rgba(152, 130, 112,.2)',
                'rgba(253, 247, 194,.2)',
                'rgba(224, 254, 254,.2)',
                'rgba(234, 235, 255,.2)',
                'rgba(182, 77, 58,.2)'              
              ],              
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,
          position: 'left',          
        }, 
        title:{
          display:true,
          text:msg3,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numero2.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.55);
          const textY = height / 1.65;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]         
      });      
    });

    this.tiposWtsReparacion.tallerWtsReparacionHistCli().subscribe (res => {      
      const taller = res['data'].map(res => res.taller)
      const numero2 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas20',{
        type: 'doughnut',
        data: {
          labels: taller,
          datasets: [
            {
              data: numero2,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'              
              ],          
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,
          position: 'left',          
        }, 
        title:{
          display:true,
          text:msg4,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numero2.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.59);
          const textY = height / 1.75;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]         
      });      
    });

    this.tiposWtsReparacion.estadoWtsReparacionCli().subscribe (res => {       
      const estado = res['data'].map(res => res.estado)
      const numero3 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas3',{
        type: 'doughnut',
        data: {
          labels: estado,
          datasets: [
            {
              data: numero3,
              borderColor:[
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'
              ],
              backgroundColor: [
                'rgba(255, 99, 132,.2)',
                'rgba(255, 159, 64,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(153, 102, 255,.2)',
                'rgba(201, 203, 207,.2)',
                'rgba(54, 162, 235,.2)',
                'rgba(255, 205, 86,.2)',
                'rgba(75, 192, 192,.2)',
                'rgba(255, 105, 97,.2)',
                'rgba(152, 130, 112,.2)',
                'rgba(253, 247, 194,.2)',
                'rgba(224, 254, 254,.2)',
                'rgba(234, 235, 255,.2)',
                'rgba(182, 77, 58,.2)'              
              ],          
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,
          position: 'left',          
        }, 
        title:{
          display:true,
          text:msg5,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numero3.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.50);
          const textY = height / 1.65;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]         
      });      
    });

    this.tiposWtsReparacion.costeWtsReparacionHistCli().subscribe (res => {       
      const estado = res['data'].map(res => res.coste)
      const numero3 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas30',{      
        type: 'doughnut',
        data: {
          labels: estado,
          datasets: [
            {
              data: numero3,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'              
              ],          
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:true,
          position: 'left',          
        }, 
        title:{
          display:true,
          text:msg6,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      },
      plugins:[{
        beforeDraw: function (chart)  {
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;
          ctx.restore();
          const fontSize = 3;
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = 'middle';  
          const total = numero3.reduce(function(tot,arr){
            return tot+arr;
          },0);
          const text = total;
          const textX = Math.round((width - ctx.measureText(text).width) / 1.61);
          const textY = height / 1.75;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]        
      });      
    });

    this.tiposWtsReparacion.factoriaWtsReparacion().subscribe (res => {    
      const factoria = res['data'].map(res => res.factoria)
      const numero4 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas4',{
        type: 'pie',
        data: {
          labels: factoria,
          datasets: [
            {
              data: numero4,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 105, 97)',
                'rgb(152, 130, 112)',
                'rgb(253, 247, 194)',
                'rgb(224, 254, 254)',
                'rgb(234, 235, 255)',
                'rgb(182, 77, 58)'              
              ],          
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,  
          position: 'left',        
        }, 
        title:{
          display:true,
          text:msg7,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.reparacionesAnuales().subscribe (res => {    
      const periodo = res['data'].map(res => res.periodo)
      const numeroRep = res['data'].map(res => res.numeroRep)     

    var chart  = new Chart('canvas5',{
        plugins:[ChartDataLabels],
        type: 'bar',
        data: {
          labels: periodo,
          datasets: [
            {
              data: numeroRep,
              backgroundColor: 'rgb(54, 162, 235)',
          }
        ]},
      options:{
        plugins:{
          datalabels:{
            align:'end',
            anchor:'end',
            color: 'blue',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },                    
                }
          }
        },
        responsive: true,
        legend: {
          labels: {
            fontColor: 'rgb(255, 99, 132)'
        },
          display: true, 
          reverse:false,          
        }, 
        title:{
          display:true,
          text: msg8,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.reparacionesAnuales().subscribe (res => {    
      const periodo = res['data'].map(res => res.periodo)
      const numeroRep = res['data'].map(res => res.numeroRep)     

    var chart  = new Chart('canvas50',{
        plugins:[ChartDataLabels],
        type: 'bar',
        data: {
          labels: periodo,
          datasets: [
            {
              data:[3.8,2.5,],
              backgroundColor: 'rgb(54, 162, 235)',                
              
          }
        ]
        },
      options:{
        plugins:{
          datalabels:{
            align:'end',
            anchor:'end',
            color: 'blue',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },                    
                }
          }
        },
        responsive: true,
        legend: {
          labels: {
            fontColor: 'rgb(255, 99, 132)'
        },
          display: true, 
          reverse:false,          
        }, 
        title:{
          display:true,
          text:msg9,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.reparacionesUltimoAno().subscribe (res => {    
      const mesTxt = res['data'].map(res => res.mesTxt)
      const sumaRep = res['data'].map(res => res.sumaRep)  
      const ejercicio = res['data'].map(res=> res.ejercicio)   

    var chart  = new Chart('canvas6',{
        plugins:[ChartDataLabels],
        type: 'bar',
        data: {
          labels: mesTxt,
          datasets: [
            {
              data: sumaRep,
              backgroundColor: 'rgb(54, 162, 235)',                       
          }
        ]
        },
      options:{
        plugins:{
          datalabels:{
            align:'end',
            anchor:'end',
            color: 'blue',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },                    
                }
          }
        },
        responsive: true,
        legend: {
          labels: {
            fontColor: 'rgb(255, 99, 132)'
        },
          display: true, 
          reverse:true,          
        }, 
        title:{
          display:true,
          text:msg10,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });
  }
  
  loadScripts(){
    const externalScriptArray = [
      
      
        '../../../assets/js/dashboard4.js'
      ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
  }
}




