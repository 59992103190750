import { Component, OnInit } from '@angular/core';

declare function init_plugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {
    
  constructor() { this.loadScripts();}

  ngOnInit() {
    init_plugins();


  }
  loadScripts(){
    const externalScriptArray = [
     
        
    
        '../../../assets/plugins/sweetalert.min.js',
        '../../../assets/plugins/datatables/jquery.dataTables.js',           
        '../../../assets/plugins/datatables/dataTables.bootstrap4.js', 
        '../../../assets/plugins/cdn_datatables/dataTables.bootstrap4.min.js',
        '../../../assets/plugins/cdn_datatables/jquery.dataTables.min.js', 
        


  
        '../../../assets/plugins/Translate.js',
        '../../../assets/plugins/Cargamensajes.js' 
      ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}
