import { Component, OnInit, OnDestroy } from '@angular/core';

//declare const mainTable :any
@Component({
  selector: 'app-Albaran_Entrada',
  templateUrl: './Albaran_Entrada.component.html',
  styleUrls: ['./Albaran_Entrada.component.css'
  ],
  
})
export class Albaran_EntradaComponent implements OnInit {
  loadAPI: Promise<any>;
  
    constructor() { this.loadScripts();}
 
    ngOnInit() {
      document.getElementById("titulo_Pagina").innerHTML = "Añadir Albaran entrada WTS";
    }
    loadScripts(){
      const externalScriptArray = [

 
        '../../../assets/plugins/cloudflare/select2.min.js',
        '../../../assets/plugins/cloudflare/popper.min.js',
        '../../../assets/plugins/cloudflare/bootstrap.min.js',
        '../../../assets/plugins/cdn_datatables/dataTables.buttons.min.js',
        '../../../assets/plugins/cdn_datatables/buttons.bootstrap4.min.js',
        '../../../assets/plugins/cloudflare/jszip.min.js',
        '../../../assets/plugins/cloudflare/pdfmake.min.js',
        '../../../assets/plugins/cloudflare/vfs_fonts.js',
        '../../../assets/plugins/cdn_datatables/buttons.html5.min.js',
        '../../../assets/plugins/cdn_datatables/buttons.print.min.js',
        '../../../assets/plugins/cdn_datatables/buttons.colVis.min.js',

        '../../../assets/plugins/Albaran_entrada.js',
        
        ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
    }
}


