import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TablasMaestrasService } from '../../services/tablas-maestras.service';
import Swal from 'sweetalert2';
import $ from "jquery";


var ln = navigator.language;

var msg1,msg2,msg3,msg4,msg5,msg6,msg7,msg8,msg9,msg10,msg11,msg12,msg13,msg14,msg15,msg16,msg17,msg18
var carcoste=40
var carestado=30
var carinsta=25
var carrol=20
var cartip=50


if ((ln == 'en-EN') || (ln == 'en')) {
  msg1='Incorrect',
  msg2='Right',
  msg3='The cost has been created correctly',
  msg4='Cost field ID cannot be left empty',
  msg5='The state type has been created successfully',
  msg6='The Status field cannot be left empty',
  msg7='The installation type has been created successfully',
  msg8='The Installation field cannot be left empty',
  msg9='The factory field has been created correctly',
  msg10='The Factory field cannot be left empty',
  msg11='The workshop field has been created correctly',
  msg12='The Workshop field cannot be left empty',
  msg13='The role field has been created successfully',
  msg14='The Role field cannot be left empty'
  msg15='Wts type has been created successfully',
  msg16='The WTS Type field cannot be left empty',
  msg17='You must select a record'
} else if ((ln == 'es') || (ln == 'es-ES')) {
  msg1='Incorrecto',
  msg2='Correcto',
  msg3='El coste ha sido creado correctamente',
  msg4='El ID del campo de coste no puede dejarse vacío',
  msg5='El tipo de estado ha sido creado correctamente',
  msg6='El campo Estado no puede dejarse vacío',
  msg7='El tipo de instalacion ha sido creada correctamente',
  msg8='El campo de Instalación no puede dejarse vacío',
  msg9='El campo factoría ha sido creada correctamente',
  msg10='El campo Factoría no puede dejarse vacío',
  msg11='EL campo taller ha sido creado correctamente',
  msg12='El campo Taller no puede dejarse vacío',
  msg13='El campo rol ha sido creado correctamente',
  msg14='El campo Rol no puede dejarse vacío',
  msg15='El tipo de Wts ha sido creado correctamente',
  msg16='El campo Tipo WTS no puede dejarse vacío',
  msg17='Debe de seleccionar un registro',
  msg18='Ha excedido la cantidad de caracteres admitidos'
 
  //document.getElementById("tipowts").innerHTML = "Master Tables";
} else if ((ln == 'de') || (ln == 'de-DE')) {
  msg1='Falsch',
  msg2='Recht',
  msg3='Die Kosten wurden korrekt erstellt',
  msg4='Die Kostenfeld-ID darf nicht leer bleiben',
  msg5='Der Statustyp wurde erfolgreich erstellt',
  msg6='Das Feld Status darf nicht leer bleiben',
  msg7='Der Installationstyp wurde erfolgreich erstellt',
  msg8='Das Feld Installation darf nicht leer bleiben',
  msg9='Das Factory-Feld wurde korrekt erstellt',
  msg10='Das Feld Factory darf nicht leer gelassen werden',
  msg11='Das Werkstattfeld wurde korrekt angelegt',
  msg12='Das Feld Werkstatt darf nicht leer bleiben',
  msg13='Das Rollenfeld wurde erfolgreich erstellt',
  msg14='Das Feld Rolle darf nicht leer bleiben',
  msg15='Der Wts-Typ wurde erfolgreich erstellt',
  msg16='Das Feld WTS-Typ darf nicht leer bleiben',
  msg17='Sie müssen einen Datensatz auswählen',
  msg18='Anzahl der unterstützten Zeichen überschritten'
} else if ((ln == 'ca')|| (ln == 'ca-ES')) {
  msg1='Incorrecte',
  msg2='Correcte',
  msg3='El cost ha estat creat correctament',
  msg4='La ID de el camp de cost no pot deixar-se buida',
  msg5="El tipus d'estat ha estat creat correctament",
  msg6='El camp Estat no pot deixar-se buit',
  msg7="El tipus d'instal·lació ha estat creada correctament",
  msg8="El camp d'Instal·lació no pot estar buit",
  msg9='El camp factoria ha estat creada correctament',
  msg10='El camp Factoria no pot estar buit',
  msg11='EL camp taller ha estat creat correctament',
  msg12='El camp Taller no pot estar buit',
  msg13='El camp rol ha estat creat correctament',
  msg14='El camp Rol no pot estar buit',
  msg15='El tipus de Wts ha estat creat correctament',
  msg16='El camp Tipus WTS no pot estar buit',
  msg17='Ha de seleccionar un registre',
  msg18="S'ha superat el nombre de caràcters admès"
} else if ((ln == 'pt-PT') || (ln == 'pt-BR')||(ln == 'pt')) {
  msg1='Incorreta',
  msg2='Certo',
  msg3='O custo foi criado corretamente',
  msg4='O ID do campo de custo não pode ser deixado em branco',
  msg5='O tipo de estado foi criado com sucesso',
  msg6='O campo Status não pode ser deixado em branco',
  msg7='O tipo de instalação foi criado com sucesso',
  msg8='O campo de instalação não pode ser deixado vazio',
  msg9='O campo de fábrica foi criado corretamente',
  msg10='O campo Fábrica não pode ser deixado vazio',
  msg11='O campo da oficina foi criado corretamente',
  msg12='O campo Workshop não pode ser deixado vazio',
  msg13='O campo de função foi criado com sucesso',
  msg14='O campo Função não pode ser deixado vazio',
  msg15='O tipo WTS foi criado com sucesso',
  msg16='O campo WTS Tipo não pode ser deixado em branco',
  msg17='Você deve selecionar um registro',
  msg18='Excedeu o número de caracteres Admitido'
} else if ((ln == 'fr-FR') || (ln == 'fr')) {
  msg1='Incorrect',
  msg2="C'est Correct",
  msg3='Le coût a été créé correctement',
  msg4="L'ID du champ de coût ne peut pas être laissé vide",
  msg5="Le type d'état a été créé avec succès",
  msg6='Le champ Statut ne peut pas être laissé vide',
  msg7="Le type d'installation a été créé avec succès",
  msg8='Le champ Installation ne peut pas être laissé vide',
  msg9="Le champ d'usine a été créé correctement",
  msg10='Le champ Usine ne peut pas être laissé vide',
  msg11='Le champ atelier a été créé correctement',
  msg12='Le champ Atelier ne peut pas être laissé vide',
  msg13='Le champ de rôle a été créé avec succès',
  msg14='Le champ Rôle ne peut pas être laissé vide',
  msg15='Le type Wts a été créé avec succès',
  msg16='Le champ Type WTS ne peut pas être laissé vide',
  msg17='Vous devez sélectionner un enregistrement',
  msg18='Dépassement du nombre de caractères Admis'
}else{
  msg1='Incorrect',
  msg2='Right',
  msg3='The cost has been created correctly',
  msg4='Cost field ID cannot be left empty',
  msg5='The state type has been created successfully',
  msg6='The Status field cannot be left empty',
  msg7='The installation type has been created successfully',
  msg8='The Installation field cannot be left empty',
  msg9='The factory field has been created correctly',
  msg10='The Factory field cannot be left empty',
  msg11='The workshop field has been created correctly',
  msg12='The Workshop field cannot be left empty',
  msg13='The role field has been created successfully',
  msg14='The Role field cannot be left empty'
  msg15='Wts type has been created successfully',
  msg16='The WTS Type field cannot be left empty',
  msg17='You must select a record',
  msg18='Exceeded the number of characters Admitted'
}


@Component({
  selector: 'app-tablas-maestras',
  templateUrl: './tablas-maestras.component.html',
  styleUrls: ['./tablas-maestras.component.css']
})
export class TablasMaestrasComponent implements OnInit {
  
  public coste = [];
  public costesForm = this.fb.group({
    idCoste: ['', Validators.required,],
    descripcionCoste: ['', Validators.required],
  });

  public estadoReparacion = [];
  public estadosForm = this.fb.group({
    idOrd: ['', Validators.required,],
    estado: ['', Validators.required],
  });

  public instalacion = [];
  public instalacionesForm = this.fb.group({
    instalacion: ['', Validators.required],
  });

  public factoria = [];
  public factoriasForm = this.fb.group({
    factoria: ['', Validators.required],
  });

  public taller = [];
  public talleresForm = this.fb.group({
    taller: ['', Validators.required],
  });

  public rol = [];
  public rolesForm = this.fb.group({
    role: ['', Validators.required],
  });

  public tiposWts = [];
  public tiposWtsForm = this.fb.group({
    tipowts: ['', Validators.required],
  });

  public clientesAlbaran =[];
  public clientesAlbaranForm = this.fb.group({
    CodigoCliente:['', Validators.required],
    RazonSocial: ['', Validators.required],
  });

  public clientes =[];


  constructor(private TablasMaestrasService: TablasMaestrasService, private fb: FormBuilder) {this.loadScripts()}

  ngOnInit() {
    
    this.cargar("coste");
    this.cargar("estadoReparacion");
    this.cargar("instalacion");
    this.cargar("factoria");
    this.cargar("taller");
    this.cargar("rol");
    this.cargar("tiposWts");
    this.cargar("clientesAlbaran");
    //this.cargar("clientes");
    document.getElementById("titulo_Pagina").innerHTML = "Tablas Maestras";
 
  }
  
  cargar(tabla: string) {
    this.TablasMaestrasService.leer(tabla).subscribe((res) => {
      eval("this." + tabla + " = res['data'];"); // Ejecuta un string como codigo
    });
    
  }

  // cargarCostes() {
  //   this.TablasMaestrasService.leerCostes().subscribe((res) => {
  //     console.log(res);
  //     this.costes = res['data'];
  //   });
  // }

  // crear(tabla:string, form:string, masc:boolean) {
  //   eval 
  //   ("this.TablasMaestrasService.crear(tabla, this." + form + ".value)" +
  //       ".subscribe(res =>{" +
  //         "this.cargar(tabla);" +
  //         "if (masc){" +
  //           "this.alertInEval('Correcto', 'El ' + tabla + ' ha sido creado correctamente', 'success');" +
  //         "} else {" +
  //           "this.alertInEval('Correcto', 'La ' + tabla + ' ha sido creada correctamente', 'success');}" +
  //       "},(err)=>console.warn(err));");
  // }

  // La funcion swal no funciona dentro de la funcion eval
  // alertInEval(estado, msg, icon) {
  //   Swal.fire(estado, msg, icon);
  // }

  crearCoste() {
    if (this.costesForm.value.idCoste != "") {
      
      if (this.costesForm.value.descripcionCoste.length <= carcoste){
          this.TablasMaestrasService.crearCoste(this.costesForm.value)
          .subscribe(res => {
          this.cargar("coste");
          Swal.fire(msg2, msg3, 'success');
        }, (err) => console.warn(err));
    
    }else{
      Swal.fire(msg1, msg18 +":  "+ carcoste, 'error')
    }} else {
      Swal.fire(msg1, msg4, 'error');
    
  }
  }

  crearEstado() {
    if (this.estadosForm.value.estado != "") {
      if (this.estadosForm.value.estado.length <= carestado){
        this.TablasMaestrasService.crearEstado(this.estadosForm.value)
          .subscribe(res => {
            this.cargar("estadoReparacion");
            Swal.fire(msg2, msg5, 'success');
          }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ carestado, 'error')
    }} else {
      Swal.fire( msg1, msg6, 'error');
    }
  }

  crearInstalacion() {
    if (this.instalacionesForm.value.instalacion != "") {
      if (this.instalacionesForm.value.instalacion.length <= carinsta){
      this.TablasMaestrasService.crearInstalacion(this.instalacionesForm.value)
        .subscribe(res => {
          this.cargar("instalacion");
          Swal.fire(msg2, msg7, 'success');
        }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ carinsta, 'error')
    }} else {
      Swal.fire( msg1, msg8, 'error');
    }
  }

  crearFactoria() {
    if (this.factoriasForm.value.factoria != "") {
      if (this.factoriasForm.value.factoria.length <= carestado){
      this.TablasMaestrasService.crearFactoria(this.factoriasForm.value)
        .subscribe(res => {
          this.cargar("factoria");
          Swal.fire(msg2, msg9, 'success');
        }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ carestado, 'error')
    }} else {
      Swal.fire(msg1, msg10, 'error');
    }
  }

  crearTaller() {
    if (this.talleresForm.value.taller != "") {
      if (this.talleresForm.value.taller.length <= carcoste){
      this.TablasMaestrasService.crearTaller(this.talleresForm.value)
        .subscribe(res => {
          this.cargar("taller");
          Swal.fire(msg2, msg11, 'success');
        }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ carcoste, 'error')
    }} else {
      Swal.fire(msg1, msg12, 'error');
    }
  }

  crearRol() {
    if (this.rolesForm.value.role != "") {
      if (this.rolesForm.value.role.length <= carrol){
      this.TablasMaestrasService.crearRol(this.rolesForm.value)
        .subscribe(res => {
          this.cargar("rol");
          Swal.fire(msg2, msg13, 'success');
        }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ carrol, 'error')
    }} else {
      Swal.fire(msg1, msg14, 'error');
      
    }
  }

  crearTipoWts() {
    if (this.tiposWtsForm.value.tipowts != "") {
      if (this.tiposWtsForm.value.tipowts.length <= cartip){
      this.TablasMaestrasService.crearTipoWts(this.tiposWtsForm.value)
        .subscribe(res => {
          this.cargar("tiposWts");
          Swal.fire(msg2, msg15, 'success');
        }, (err) => console.warn(err));
      }else{
        Swal.fire(msg1, msg18 +":  "+ cartip, 'error')
    }} else {
      Swal.fire(msg1, msg16, 'error');
    }
  }
/*
pruebasboton(tabla: string, masc: boolean){
  var guardarCliente = "";
  var selectedId = "#" + tabla + ">tbody>tr.selected>td.id";
  guardarCliente = $(selectedId).text();
  alert(guardarCliente);

  // if(guardarCliente !="") {
  //   this.TablasMaestrasService.guardarCliente(guardarCliente).subscribe(res=>{
  //     this.cargar(tabla);
  //   });
  // }else{
  //   Swal.fire(msg1, msg17, 'error');
  // }
}
*/
  borrar(tabla: string, masc: boolean) {
    var borrarId = "";
    var selectedId = "#" + tabla + ">tbody>tr.selected>td.id";
    borrarId = $(selectedId).text();
    if (borrarId != "") {
      this.TablasMaestrasService.eliminar(tabla, borrarId, masc).subscribe(res => {
        this.cargar(tabla);
      });
    } else {
      Swal.fire(msg1, msg17, 'error');
    }
  }

  releer(tabla: string) {
    
    this.cargar(tabla);
  }


  
  // borrarCoste() {
  //   let borrarId = $('tr.selected>td.id').text();
  //   console.log(borrarId);
  //   this.TablasMaestrasService.eliminarCoste(borrarId).subscribe(res => {
  //     this.cargar("coste");
  //   });
  // }
  loadScripts(){
    const externalScriptArray = [
      
 
      '../../../assets/plugins/cdn_datatables/dataTables.select.min.js',

 
     '../../../assets/js/TablaMaestra.js'
 
      ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
 
  
}