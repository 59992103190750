import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { datosWts } from '../../models/datosWts.model';



@Injectable({
  providedIn: 'root'
})
export class DatosWtsService {

  constructor(
    public http: HttpClient
  ) { }

  tiposWtsReparacion(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboard/tiposWtsRep';    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  tiposWtsReparacionCli(){    
    let token: string = localStorage.getItem('token');    
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria      
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/tiposWtsRep/'+ factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  totalReparacion(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboard/count';    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  tiposWtsReparacionHistCli(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/tiposWtsRepHist/' + factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }
  
  tallerWtsReparacion(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboard/talllerWtsRep';    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  tallerWtsReparacionCli(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria    
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/talllerWtsRep/' + factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  tallerWtsReparacionHistCli(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/talllerWtsRepHist/' + factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }
  costeWtsReparacionHistCli(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/CosteWtsRepHist/'+ factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }
  estadoWtsReparacion(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboard/estadoWtsRep';    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  estadoWtsReparacionCli(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/estadoWtsRep/' + factoria;    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }

  factoriaWtsReparacion(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparaciones/dashboardCli/factoriaWtsRep/';    
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }
  reparacionesAnuales(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparacionesAnuales/' + factoria;
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }  
  reparacionesUltimoAno(){
    let token: string = localStorage.getItem('token');
    var playload = JSON.parse(atob(token.split('.')[1]));
    var factoria = playload.usuario.factoria
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/reparacionesUltimoAno/' + factoria;
    return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  }  
  finAllDatosWts() {
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/datosWts';
    return this.http.get(url,{headers:headers}).pipe(map((resp:any)=>{
     // console.log(resp.data);
      return resp.data;
    }));
  }

  findOneDatosWtsId(id:string){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/datosWts/'+ id;
    return this.http.get(url,{headers:headers}).pipe(map((resp:any)=>resp.data));
  }

  eliminarDatosWts (id:string){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/datosWts/'+ id;
    return this.http.delete(url,{headers:headers}).pipe(map((resp=>Swal.fire({
      title:'Wts Borrada',
      text:'Eliminado Correctamente',
      icon: 'success'
    }))));
  }

  // cargarImagenesReparaciones(){
  //   let token: string = localStorage.getItem('token');
  //   var playload = JSON.parse(atob(token.split('.')[1]));    
  //   const headers: HttpHeaders = new HttpHeaders({      
  //   'x-token': `${token}`
  //   });
  //   let url = URL_SERVICIOS + '/reparaciones/7903'   
  //   return this.http.get(url,{headers:headers}).pipe(map(res=>res));
  // }
  
}
