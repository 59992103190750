import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-EnesperaCliente',
  templateUrl: './enespera-ciente.component.html',
  styleUrls: ['./enespera-ciente.component.css'],
})
export class EnesperaClienteComponent implements OnInit {

  constructor() { this.loadScripts(); }

  ngOnInit() {
    document.getElementById("titulo_Pagina").innerHTML = "Reparaciones en espera de cliente";
  }

  loadScripts() {
    const externalScriptArray = [

      '../../../assets/plugins/cdn_datatables/dataTables.bootstrap4.min.js', 
        '../../../assets/js/enesperacliente.js'
    ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}

