import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../config/config';



@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor() { }

  async actualizarFoto(archivo:File, tipo: 'usuarios'|'reparaciones'|'helpdesk', id: number) {
    try {
      const url = `${URL_SERVICIOS}/upload/${tipo}/${id}`;
      const formData = new FormData();
    //  console.log (formData);
      formData.append('imagen', archivo);
      const resp = await fetch(url, {
        method: 'PUT',        
        body: formData,                
      });      
      const data = await resp.json();   
    //  console.log('esto es la data' + data);   
      if(data.ok) {
    //    console.log('esto es el filename' + data.filename);
        return data.filename;
      }else {
    //    console.log('falla '+ data.msg);
        return false;
      }  
    } catch (error) {
   //   console.log(error);
      return false    
    }
  }
}
