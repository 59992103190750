import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartsModule } from 'ng2-charts';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardComponentClient } from './dashboard_cliente/dashboard.component';
import { ProgressComponent } from "./progress/progress.component";
import { Graficas1Component } from "./graficas1/graficas1.component";
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutes } from './pages.routes';
import { IncrementadorComponent } from '../components/incrementador/incrementador.component';
import { GraficoDonaComponent } from '../components/grafico-dona/grafico-dona.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import {Reparacion_WTSComponent} from './reparacion_WTS/reparacion_WTS.component';
import {Albaran_EntradaComponent} from './albaran-entrada/Albaran_Entrada.component';

import { CommonModule } from '@angular/common';

import { datos_wtsComponent } from './Intriduccion-datos-wts/datos_wts.component';
import { RegisterComponent } from '../login/register.component';
import { Albaran_EntradaClienteComponent } from './Albaran_entrada_cliente/Albaran_entrada_cliente.component';
import { reparacionClienteComponent } from './reparacion_WTS_cliente/reparcionCliente.component';
import { RegistroUsuariosComponent } from './registroUsuarios/registroUsuarios.component';
import { TablasMaestrasComponent } from './tablas-maestras/tablas-maestras.component';
import { HistRepClienteComponent } from './hist-rep-cliente/hist-rep-cliente.component';
import { EstadoReparacionesIntComponent } from './estado-reparaciones-int/estado-reparaciones-int.component';
import { HistorialReparacionesIntComponent } from './historial-reparaciones-int/historial-reparaciones-int.component';
import { PerfilComponent } from './perfil/perfil.component';
import { Listado_ReparacionesComponent} from './ListadoReparaciones/Listado_Reparaciones.component';
import { agendaexpedicionesComponent } from './agendaexpediciones/agendaexpediciones.component';
import { expedicionesComponent } from './expediciones/expediciones.component';
import {EnesperaClienteComponent} from'./enespera/enespera-ciente.component';
import { albaranPDFComponent } from "./Albaranpdf/AlbaranPDF.component";
import { presupuestoComponent } from './Presupuesto/presupuesto.component';
import { enpresupuestoComponent } from "./enpresupuesto/enpresupuesto.component";
import { presupuestoPDFComponent } from './presupuestoPDF/presupuestoPDF.component';
import { manualesComponent } from './manuales/manuales.component';
import { esquemasComponent } from "./esquemas/esquemas.component";
import { consultareparacionesComponent } from './consultareparaciones/consultareparaciones';








@NgModule({
    declarations: [PagesComponent, DashboardComponent, DashboardComponentClient, ProgressComponent, Graficas1Component, IncrementadorComponent, 
        GraficoDonaComponent, AccountSettingsComponent, PromesasComponent, RxjsComponent, Reparacion_WTSComponent, Albaran_EntradaComponent,
        GraficoDonaComponent, AccountSettingsComponent, PromesasComponent, RxjsComponent, Reparacion_WTSComponent, Albaran_EntradaComponent, 
        datos_wtsComponent, RegisterComponent, Albaran_EntradaClienteComponent,reparacionClienteComponent, RegistroUsuariosComponent, TablasMaestrasComponent, 
        HistRepClienteComponent, EstadoReparacionesIntComponent, HistorialReparacionesIntComponent, PerfilComponent, Listado_ReparacionesComponent,
        agendaexpedicionesComponent, expedicionesComponent, EnesperaClienteComponent,albaranPDFComponent,presupuestoComponent,enpresupuestoComponent,
        presupuestoPDFComponent, manualesComponent, esquemasComponent, consultareparacionesComponent],
    exports: [PagesComponent, DashboardComponent, ProgressComponent, Graficas1Component],
    imports:[SharedModule, PagesRoutes, FormsModule, ChartsModule, CommonModule, ReactiveFormsModule ]
})
export class PagesModules { }
