import { Component, OnInit } from '@angular/core';
import Dropzone from 'dropzone';




@Component({
  selector: 'app-reparacion_WTS',
  templateUrl: './reparacion_WTS.component.html',
  styleUrls: ['./reparacion_WTS.component.css'],
    
})
export class Reparacion_WTSComponent implements OnInit {  

  
  
  constructor() { this.loadScripts();}

    ngOnInit() {
      document.getElementById("titulo_Pagina").innerHTML = "Reparacion de WTS";      
      
    }
    loadScripts(){
      const externalScriptArray = [       


        '../../../assets/plugins/cloudflare/select2.min.js',
        '../../../assets/plugins/cloudflare/dropzone.min.js',
        '../../../assets/plugins/Reparacion_WTS.js'
        ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
    }

}
