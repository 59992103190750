import { Injectable } from '@angular/core';
import decode from 'jwt-decode';



var ln = navigator.language;

var menu1,menu2,menu3,menu4,menu5,menu6,menu7,menu8,menu9,menu10,menu11,menu12,menu13,menu14,menu15,menu16,menu17
var menu18,menu19,menu20,menu21,menu22,menu23,menu24
if ((ln == 'en-EN') || (ln == 'en')) {
  menu1='Administration',
  menu2='Users',
  menu3='Master Tables',
  menu4='Internal management',
  menu5='Dashboard',
  menu6='WTS Data Introduction',
  menu7='Add Albaran Entry',
  menu8='WTS repair',
  menu9='Client Management',
  menu10='Status Repairs',
  menu11='History Repairs',
  menu12='Entry Delivery Notes History',
  menu13='Manuals',
  menu14='List of Repairs',
  menu15='expedition agenda',
  menu16='Expeditions',
  menu17='Waiting for customer',
  menu18='WTS Repair Status',
  menu19='History Repairs',
  menu20='History Incoming Delivery Notes',
  menu21='Budget',
  menu22='in budget',
  menu23='Schemes',
  menu24='repair consultations'
  
} else if ((ln == 'es') || (ln == 'es-ES')) {
  menu1='Administracion',
  menu2='Usuarios',
  menu3='Tablas Maestras',
  menu4='Gestión Interna',
  menu5='Tablero de control',
  menu6='Introducción Datos WTS',
  menu7='Añadir Albaran Entrada',
  menu8='Reparacion WTS',
  menu9='Gestión Cliente',
  menu10='Reparaciones en Curso',
  menu11='Historial Reparaciones',
  menu12='Historial Albaranes Entrada',
  menu13='Manuales',
  menu14='Listado Reparaciones',
  menu15='Agenda de expediciones',
  menu16='Expediciones',
  menu17='En Espera de Cliente',
  menu18='Reparaciones en curso',
  menu19='Historial Reparaciones',
  menu20='Historial Albaranes Entrada',
  menu21='Presupuesto',
  menu22='En presupuesto',
  menu23='Esquemas',
  menu24='Consulta Reparaciones'

} else if ((ln == 'de') || (ln == 'de-DE')) {
  menu1='Verwaltung',
  menu2='Benutzer',
  menu3='Master Tables',
  menu4='Internes Management',
  menu5='Instrumententafel',
  menu6='Einführung in WTS-Daten',
  menu7='Albaran-Eintrag hinzufügen',
  menu8='WTS-Reparatur',
  menu9='Client-Management',
  menu10='Statusreparaturen',
  menu11='Historienreparaturen',
  menu12='Eintrag Liefernotizen Verlauf',
  menu13='Anleitungen',
  menu14='Liste der Reparaturen',
  menu15='Expeditionsagenda',
  menu16='Expeditionen',
  menu17='Auf Kunden warten',
  menu18='WTS-Reparaturstatus',
  menu19='Historienreparaturen',
  menu20='Historie Eingehende Lieferscheine',
  menu21='Budget',
  menu22='im Budget',
  menu23='Schemata',
  menu24='Reparaturberatungen'

} else if ((ln == 'ca')|| (ln == 'ca-ES')){
  menu1='Administració',
  menu2='Usuaris',
  menu3='Taules Mestres',
  menu4='Gestió Interna',
  menu5='Tauler de control',
  menu6='Introducció Dades WTS',
  menu7='Afegir Albarà Entrada',
  menu8='Reparació WTS',
  menu9='Gestió Client',
  menu10='Estat Reparacions',
  menu11='Històric Reparacions',
  menu12='Històric Albarans Entrada',
  menu13='Manuals',
  menu14='Llistat Reparacions',
  menu15="Agenda d'expedicions",
  menu16='Expedicions',
  menu17='Esperant client',
  menu18='Reparacions en Curs',
  menu19='Històric Reparacions',
  menu20='Històric Albarans Entrada',
  menu21='Pressupost',
  menu22='en pressupost',
  menu23='Esquemes',
  menu24='consulta reparacions'

} else if ((ln == 'pt-PT') || (ln == 'pt-BR')||(ln == 'pt')) {
  menu1='Administração',
  menu2='Do utilizador',
  menu3='Mesas Mestre',
  menu4='Gestão interna',
  menu5='Painel de controle',
  menu6='Introdução de dados WTS',
  menu7='Adicionar entrada Albaran',
  menu8='Reparo WTS',
  menu9='Gestão de clientes',
  menu10='Reparos de status',
  menu11='História de Reparos ',
  menu12='Registro Notas de entrega de entrada',
  menu13='Manuais',
  menu14='Lista de Reparos',
  menu15='agenda da expedição',
  menu16='Expedições',
  menu17='Esperando pelo cliente',
  menu18='Status de reparo WTS',
  menu19='Reparos de História',
  menu20='Histórico de notas de entrega de entrada',
  menu21='Orçamento',
  menu22='no orçamento',
  menu23='Regimes',
  menu24='consultas de reparação'
} else if ((ln == 'fr-FR') || (ln == 'fr')) {
  menu1='Administration',
  menu2='utilisateurs',
  menu3='Tables principales',
  menu4= 'Gestion interne',
  menu5='Tableau de bord',
  menu6='Présentation des données WTS',
  menu7='Ajouter une entrée Albaran',
  menu8='Réparation WTS',
  menu9='La gestion des clients',
  menu10='Réparation de statut',
  menu11='Réparations historiques',
  menu12="Historique des notes de livraison d'entrée",
  menu13='Manuels',
  menu14='Liste des réparations',
  menu15="agenda de l'expédition",
  menu16='Expéditions',
  menu17='En attente du client',
  menu18='État de réparation WTS',
  menu19='Réparations historiques',
  menu20='Historique des bons de livraison entrants',
  menu21='Budget',
  menu22='dans le budget',
  menu23='Régimes',
  menu24='consultations sur les réparations'

}else {
  menu1='Administration',
  menu2='Users',
  menu3='Master Tables',
  menu4='Internal management',
  menu5='Dashboard',
  menu6='WTS Data Introduction',
  menu7='Add Albaran Entry',
  menu8='WTS repair',
  menu9='Client Management',
  menu10='Status Repairs',
  menu11='History Repairs',
  menu12='Entry Delivery Notes History',
  menu13='Manuals',
  menu14='List of Repairs',
  menu15='expedition agenda',
  menu16='Expeditions',
  menu17='Waiting for customer',
  menu18='WTS Repair Status',
  menu19='History Repairs',
  menu20='History Incoming Delivery Notes'
  menu21='Budget',
  menu22='in budget',
  menu23='Schemes',
  menu24='repair consultations'
}


@Injectable({
  providedIn: 'root'
})
export class SidebarService {   

  menuAdmin: any = [
    {
      id: 'adminMenu',
      titulo: menu1,
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu2, url:'/registroUsuarios'},        
        { titulo: menu3, url:'/TablasMaestras' },
        { titulo: menu15, url:'/agendaexpediciones'}, 
      ]  
    },
    {
      titulo: menu4,
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu5, url:'/dashboard' },
        { titulo: menu6, url:'/datos_wts'},
        { titulo: menu7,url:'/Albaran_Entrada'},
        { titulo: menu8, url:'/reparacion_WTS'},   
        { titulo: menu14, url:'/ListadoReparaciones'},  
        { titulo: menu16, url:'/expediciones'},
        { titulo: menu17, url:'/enespera'},
        {titulo:menu21,url:'/presupuesto'},
        {titulo:menu22,url:'/enpresupuesto'},
        {titulo:menu24,url:'/consultareparaciones'}      
      ]  
    },{
      titulo: menu9,
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu5, url:'/dashboardClient'},
        { titulo: menu10 ,url:'/EstadoReparacionesInt'},
        { titulo: menu11 ,url:'/HistorialReparacionesInt'},
        { titulo: menu12 ,url:'/Albaran_entrada_cliente'},
        
      ]
    },{
      titulo: 'HelpDesk',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu13 ,url:'/manuales'},
        { titulo: menu23 ,url:'/esquemas'},
      ]
    }
  ]
  menuUser: any = [
    {
      titulo: 'Support',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu5, url:'/dashboardClient' },
        { titulo: menu18, url:'/reparacion_WTS_cliente'},
        { titulo: menu19, url:'/histRepCliente'},
        { titulo: menu20, url:'/Albaran_entrada_cliente'},
      ]
    },{
      titulo: 'HelpDesk',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: menu13 ,url:'/manuales'},
        { titulo: menu23 ,url:'/esquemas'},
      ]
    }
    // ,{
    //   titulo: 'HelpDesk',
    //   icono: 'mdi mdi-gauge',
    //   submenu: [
    //     { titulo: 'Manuales',url:''},
    //   ]
    // }
  ]

  constructor() { }
}
