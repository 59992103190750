import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatosWtsService } from './wts/datos-wts.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptorService } from './auth-interceptor.service';

import { SettingsService, SharedService, SidebarService} from './service.index';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers:[
    SettingsService, 
    SharedService, 
    SidebarService,
    DatosWtsService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptorService,
    //   multi: true
    // }
  ]
})
export class ServiceModule { }
