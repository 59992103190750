import { Component, OnInit } from '@angular/core';
import { Observable, observable, Subscriber } from 'rxjs';
import { retry,map } from 'rxjs/operators';

@Component({
  selector: 'app-rxjs',
  templateUrl: './rxjs.component.html',
  styles: []
})
export class RxjsComponent implements OnInit {

  constructor() {     
    this.RegresaObservable().subscribe(
      numero => console.log('Subs', numero),
      error => console.error('Error en el obs', error),
      () => console.log('El obsevable termiano!')
    );
  }

  ngOnInit() { 
    document.getElementById("titulo_Pagina").innerHTML = "rxjs"; 
  }

  RegresaObservable():Observable<any>{
    return new Observable((observer: Subscriber<any>) =>{
      let contador = 1;
      let interval = setInterval(()=>{
        contador += 1;
        const salida ={
          valor:contador
        };
        observer.next(salida);
        if (contador === 3){
          clearInterval(interval);
          observer.complete();
        }
        // if (contador === 2){
        //   // clearInterval(interval);
        //   observer.error('Auxilio!');
        // }
      }, 1000 );
    }).pipe(map(resp=>{
      
    }))
  }
}
