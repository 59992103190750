import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import  Swal  from 'sweetalert2';

var ln = navigator.language;

var msg1,msg2,msg3
if ((ln == 'en-EN') || (ln == 'en')) {
  msg1='Incorrect',
  msg2='Right',
  msg3='User has been created successfully' 
  
} else if ((ln == 'es') || (ln == 'es-ES')) {
  msg1='Incorrecto',
  msg2='Correcto',
  msg3='El usuario ha sido creado correctamente'
 
  //document.getElementById("tipowts").innerHTML = "Master Tables";
} else if ((ln == 'de') || (ln == 'de-DE')) {
  msg1='Falsch',
  msg2='Recht',
  msg3='Benutzer wurde erfolgreich erstellt'
} else if ((ln == 'ca')|| (ln == 'ca-ES')) {
  msg1='Incorrecte',
  msg2='Correcte',
  msg3="L'usuari ha estat creat correctament"
} else if ((ln == 'pt-PT') || (ln == 'pt-BR')||(ln == 'pt')) {
  msg1='Incorreta',
  msg2='Certo',
  msg3='O usuário foi criado com sucesso'
} else if ((ln == 'fr-FR') || (ln == 'fr')) {
  msg1='Incorrect',
  msg2="C'est Correct",
  msg3="L'utilisateur a été créé avec succès"
}else{
  msg1='Incorrect',
  msg2='Right',
  msg3='User has been created successfully' 
  
}
@Component({
  selector: 'app-registro-usuarios',
  templateUrl: './registroUsuarios.component.html',
  styles: []
})
export class RegistroUsuariosComponent  {

  public formSubmitted = false;

  public registerForm = this.fb.group({
    nombreUsuario: ['', Validators.required,],
    email: ['', Validators.required],
    password: ['', Validators.required],
    role: ['', Validators.required],
    factoria: ['T', Validators.required],
    activo: ['', Validators.required],
    FechaCreacion: ['', Validators.required],
    FechaModificacion: ['', Validators.required],
  });

  public usuarios = []; 
  public factorias = [];
  public roles = [];

  constructor(private fb: FormBuilder, private UsuarioService:UsuarioService) {this.loadScripts() }

  CrearUsuarios() {
    this.formSubmitted = true;
   // console.log(this.registerForm.value);
    this.UsuarioService.CrearUsuario( this.registerForm.value)
        .subscribe(resp =>{
       //   console.log('Usuario Creado');
      //    console.log(resp);
          this.cargarUsuarios();
          Swal.fire(msg2, msg3, 'success');
        },(err)=>console.warn(err));
  }
  
  MostrarRole(){
    Swal.fire(msg2, msg3, 'success');
  }

  cargarUsuarios() {
      this.UsuarioService.leerUsuarios().subscribe((res) => {
        this.usuarios = res['usuarios'];
      });
    }

  cargarFactorias() {
    this.UsuarioService.leerFactoria().subscribe((res) => {
      this.factorias = res['data'];
    });
  }

  cargarRoles() {
    this.UsuarioService.leerRol().subscribe((res) => {
      this.roles = res['data'];
    });
  }

  ngOnInit(): void {
    this.cargarUsuarios();
    this.cargarFactorias();
    this.cargarRoles();
    document.getElementById("titulo_Pagina").innerHTML = "Creacion de Usuarios";
   
  }
  loadScripts(){
    const externalScriptArray = [
  
      '../../../assets/js/Usuarios.js',
      
     // '../../../assets/plugins/Cargamensajes.js'
 
      ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}
