import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DatosWtsService } from '../../services/wts/datos-wts.service';
import {draw, generate,} from 'patternomaly';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  title = "app";
  chart = [];
  loadAPI: Promise<any>;
  constructor(private tiposWtsReparacion: DatosWtsService) {    
    this.loadScripts();
    Chart.plugins.unregister(ChartDataLabels);
  }  

  ngOnInit() {
    
    var ln = navigator.language;

    var msg1,msg2,msg3,msg4,msg5,msg6
    if ((ln == 'en-EN') || (ln == 'en')) {
      msg1="WTS by Installation";
      msg2="WTS by workshop";
      msg3="WTS by state";
      msg4="WTS by Factory";
      msg5="Annual repair history";
      msg6="Repairs in the last 14 months";
      document.getElementById("titulo_Pagina").innerHTML = "Control Board";
      document.getElementById("Enreparacion").innerHTML = "In repair";
      document.getElementById("Lentrega").innerHTML = "Ready for delivery";
    
    } else if ((ln == 'es') || (ln == 'es-ES')) {
      msg1="WTS por Instalaciones";
      msg2="WTS por Taller";
      msg3="WTS por Estado";
      msg4="WTS por Factoria";
      msg5="Histórico anual de reparaciones";
      msg6="Reparaciones de ultimos 14 meses";
      document.getElementById("titulo_Pagina").innerHTML = "Tablero de control";
      document.getElementById("Enreparacion").innerHTML = "En reparación";
      document.getElementById("Lentrega").innerHTML = "Lista para la entrega";
    
    } else if ((ln == 'de') || (ln == 'de-DE')) {
      msg1="WTS nach Installation";
      msg2="WTS nach Werkstatt";
      msg3="WTS nach Bundesland";
      msg4="WTS von der Fabrik";
      msg5="Jährliche Reparaturhistorie";
      msg6="Reparaturen in den letzten 14 Monaten";
      document.getElementById("titulo_Pagina").innerHTML = "Steuerplatine";
      document.getElementById("Enreparacion").innerHTML = "In Reparatur";
      document.getElementById("Lentrega").innerHTML = "Lieferbereit";
    
    } else if ((ln == 'ca')|| (ln == 'ca-ES')){
      msg1="WTS per Instal·lacions";
      msg2="WTS per a taller";
      msg3="WTS per Estat";
      msg4="WTS per Factoria";
      msg5="Històric anual de reparacions";
      msg6="Reparacions de ultims 14 mesos";
      document.getElementById("titulo_Pagina").innerHTML = "Tauler de control";
      document.getElementById("Enreparacion").innerHTML = "En reparació";
      document.getElementById("Lentrega").innerHTML = "Llest per al lliurament";
    
     
    } else if ((ln == 'pt-PT') || (ln == 'pt-BR')||(ln == 'pt') ) {
      msg1="WTS por instalações";
      msg2="WTS por oficina";
      msg3="WTS por estado";
      msg4="WTS pela fábrica";
      msg5="Histórico anual de reparos";
      msg6="Reparos nos últimos 14 meses";
      document.getElementById("titulo_Pagina").innerHTML = "Painel de controle";
      document.getElementById("Enreparacion").innerHTML = "Em reparo";
      document.getElementById("Lentrega").innerHTML = "Pronto para entrega";
    
    } else if ((ln == 'fr-FR') || (ln == 'fr')) {
      msg1="WTS par installations";
      msg2="WTS par atelier";
      msg3="WTS par état";
      msg4="WTS par Factory";
      msg5="Historique des réparations annuelles";
      msg6="Réparations au cours des 14 derniers mois";
      document.getElementById("titulo_Pagina").innerHTML = "Tableau de contrôle";
      document.getElementById("Enreparacion").innerHTML = "Dans la réparation";
      document.getElementById("Lentrega").innerHTML = "Prêt pour la livraison";
    
    }else {
      msg1="WTS by Installation";
      msg2="WTS by workshop";
      msg3="WTS by state";
      msg4="WTS by Factory";
      msg5="Annual repair history";
      msg6="Repairs in the last 14 months";
      document.getElementById("titulo_Pagina").innerHTML = "Control Board";
      document.getElementById("Enreparacion").innerHTML = "In repair";
      document.getElementById("Lentrega").innerHTML = "Ready for delivery";
    }
    this.tiposWtsReparacion.tiposWtsReparacion().subscribe (res => {     
      const instalacion = res['data'].map(res => res.instalacion)
      const numero = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas',{
        type: 'doughnut',
        data: {
          labels: instalacion,
          datasets: [
            {
              data: numero,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
              ],
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,    
          position: 'left',      
        }, 
        title:{
          display:true,
          text: msg1,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.tallerWtsReparacion().subscribe (res => {      
      const taller = res['data'].map(res => res.taller)
      const numero2 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas2',{
        type: 'pie',
        data: {
          labels: taller,
          datasets: [
            {
              data: numero2,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
              ],
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,
          position: 'left',          
        }, 
        title:{
          display:true,
          text: msg2,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });
    this.tiposWtsReparacion.estadoWtsReparacion().subscribe (res => {       
      const estado = res['data'].map(res => res.estado)
      const numero3 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas3',{
        type: 'polarArea',
        data: {
          labels: estado,
          datasets: [
            {
              data: numero3,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
                'rgba(182, 77, 58)'
              ],
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,
          position: 'left',          
        }, 
        title:{
          display:true,
          text: msg3,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });
    this.tiposWtsReparacion.factoriaWtsReparacion().subscribe (res => {    
      const factoria = res['data'].map(res => res.factoria)
      const numero4 = res['data'].map(res => res.numero)

    var chart  = new Chart('canvas4',{
        type: 'pie',
        data: {
          labels: factoria,
          datasets: [
            {
              data: numero4,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)',
              ],
          }
        ]
        },
      options:{
        responsive: true,
        legend: {
          display: true, 
          reverse:false,  
          position: 'left',        
        }, 
        title:{
          display:true,
          text: msg4,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.reparacionesAnuales().subscribe (res => {    
      const periodo = res['data'].map(res => res.periodo)
      const numeroRep = res['data'].map(res => res.numeroRep)     

    var chart  = new Chart('canvas5',{
        plugins:[ChartDataLabels],
        type: 'bar',
        data: {
          labels: periodo,
          datasets: [
            {
              data: numeroRep,
              backgroundColor: 'rgb(54, 162, 235)',                
              
          }
        ]
        },
      options:{
        plugins:{
          datalabels:{
            align:'end',
            anchor:'end',
            color: 'blue',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },                    
                }
          }
        },
        responsive: true,
        legend: {
          labels: {
            fontColor: 'rgb(255, 99, 132)'
        },
          display: true, 
          reverse:false,          
        }, 
        title:{
          display:true,
          text: msg5,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });

    this.tiposWtsReparacion.reparacionesUltimoAno().subscribe (res => {    
      const mesTxt = res['data'].map(res => res.mesTxt)
      const sumaRep = res['data'].map(res => res.sumaRep)     

    var chart  = new Chart('canvas6',{
        plugins:[ChartDataLabels],
        type: 'bar',
        data: {
          labels: mesTxt,
          datasets: [
            {
              data: sumaRep,
              backgroundColor: 'rgb(54, 162, 235)',                
              
          }
        ]
        },
      options:{
        plugins:{
          datalabels:{
            align:'end',
            anchor:'end',
            color: 'blue',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },                    
                }
          }
        },
        responsive: true,
        legend: {
          labels: {
            fontColor: 'rgb(255, 99, 132)'
        },
          display: true, 
          reverse:false,          
        }, 
        title:{
          display:true,
          text: msg6,
          fontSize: 18,
        },
        animation: {
					animateScale: true,
					animateRotate: true
				}    
      }
      });      
    });
    
    }
  loadScripts(){
    const externalScriptArray = [
      
      
        '../../../assets/js/dashboard4.js'
      ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
  }
}

