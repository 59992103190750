import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../services/usuario.service';

import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private auth: UsuarioService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {    
    const token = localStorage.getItem('token');
    const tokenPayload = decode(token);    
    if (tokenPayload.usuario.role !=='ADMINISTRADOR'){
      //console.log('No tiene el role de administrador');
      this.router.navigate(['/login']);
      return false;
    }
    //console.log('Tiene el role de Adminsitrador')
    return true;
  }  
}
