import { RouterModule, Routes,} from '@angular/router';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { Reparacion_WTSComponent } from './reparacion_WTS/reparacion_WTS.component';
import { Albaran_EntradaComponent} from './albaran-entrada/Albaran_Entrada.component';
import { datos_wtsComponent } from './Intriduccion-datos-wts/datos_wts.component';
import { Albaran_EntradaClienteComponent } from './Albaran_entrada_cliente/Albaran_entrada_cliente.component';
import { reparacionClienteComponent } from './reparacion_WTS_cliente/reparcionCliente.component';
import { RegistroUsuariosComponent } from './registroUsuarios/registroUsuarios.component';
import { LoginGuard } from '../guards/login.guard';
import { RoleGuard } from '../guards/role.guard';
import { TablasMaestrasComponent } from './tablas-maestras/tablas-maestras.component';
import { HistRepClienteComponent } from './hist-rep-cliente/hist-rep-cliente.component';
import { EstadoReparacionesIntComponent } from './estado-reparaciones-int/estado-reparaciones-int.component';
import { HistorialReparacionesIntComponent } from './historial-reparaciones-int/historial-reparaciones-int.component';
import { PerfilComponent } from './perfil/perfil.component';
import { DashboardComponentClient } from './dashboard_cliente/dashboard.component';
import { agendaexpedicionesComponent } from './agendaexpediciones/agendaexpediciones.component';
import { expedicionesComponent } from './expediciones/expediciones.component';
import { albaranPDFComponent } from './Albaranpdf/AlbaranPDF.component';
import{ Listado_ReparacionesComponent} from './ListadoReparaciones/Listado_Reparaciones.component';
import {EnesperaClienteComponent} from'./enespera/enespera-ciente.component';
import { presupuestoComponent } from './Presupuesto/presupuesto.component';
import { enpresupuestoComponent } from './enpresupuesto/enpresupuesto.component';
import { presupuestoPDFComponent } from './presupuestoPDF/presupuestoPDF.component';
import{manualesComponent}from './manuales/manuales.component';
import { esquemasComponent } from './esquemas/esquemas.component';
import { consultareparacionesComponent } from './consultareparaciones/consultareparaciones';




const pagesRoutes: Routes = [
    {
        path:'', 
        component: PagesComponent,        
        children: [

            //Admin routes
            {path:'datos_wts',component:datos_wtsComponent,canActivate:[RoleGuard, LoginGuard]},
            {path:'dashboard', component: DashboardComponent,canActivateChild: [RoleGuard, LoginGuard]},
            {path:'reparacion_WTS',component:Reparacion_WTSComponent, canActivate:[RoleGuard, LoginGuard]},
            {path:'Albaran_Entrada',component:Albaran_EntradaComponent, canActivate:[RoleGuard, LoginGuard]},
            {path:'registroUsuarios',component:RegistroUsuariosComponent, canActivate:[RoleGuard,LoginGuard]},
            {path:'TablasMaestras', component:TablasMaestrasComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'EstadoReparacionesInt', component:EstadoReparacionesIntComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'HistorialReparacionesInt', component:HistorialReparacionesIntComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'perfil', component:PerfilComponent,canActivate:[LoginGuard]},
            {path:'ListadoReparaciones', component:Listado_ReparacionesComponent, canActivate:[RoleGuard, LoginGuard]},
            {path:'agendaexpediciones', component:agendaexpedicionesComponent, canActivate:[RoleGuard,LoginGuard]},
            {path:'expediciones', component:expedicionesComponent,canActivate:[RoleGuard,LoginGuard]},
			{path:'enespera', component:EnesperaClienteComponent,canActivate:[RoleGuard,LoginGuard]},
			{path:'albaranPDF', component:albaranPDFComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'presupuesto',component:presupuestoComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'enpresupuesto',component:enpresupuestoComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'presupuestoPDF',component:presupuestoPDFComponent,canActivate:[RoleGuard,LoginGuard]},
            {path:'consultareparaciones',component:consultareparacionesComponent,canActivate:[RoleGuard,LoginGuard]},
           // {path:'manuales',component:manualesComponent,canActivate:[RoleGuard,LoginGuard]},

            //Users routes
            {path:'account-settings', component:AccountSettingsComponent,canActivateChild: [LoginGuard]},
            {path:'Albaran_entrada_cliente', component:Albaran_EntradaClienteComponent,canActivateChild: [LoginGuard]},
            {path:'reparacion_WTS_cliente',component:reparacionClienteComponent,canActivateChild: [LoginGuard]},            
            {path:'histRepCliente', component:HistRepClienteComponent,canActivate:[LoginGuard]},
            {path:'dashboardClient', component: DashboardComponentClient,canActivateChild: [LoginGuard]},
            {path:'manuales',component:manualesComponent,canActivate:[LoginGuard]},
            {path:'esquemas',component:esquemasComponent,canActivate:[LoginGuard]},
            
            
            
            
            
            
            
            
            


            // {path:'', redirectTo:'/dashboard', pathMatch:'full'},
        ]
    }
];

export const PagesRoutes = RouterModule.forChild(pagesRoutes);