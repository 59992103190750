import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { APP_ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';


//Modules
import { PagesModules } from './pages/pages.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


//Services 
import { ServiceModule } from './services/service.module';
import { DatatableComponent } from './datatable/datatable.component';
import { HttpClientModule } from '@angular/common/http';
import { UsuarioService } from '../app/services/usuario.service';
import { JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DatatableComponent,
    
    
    
    
  ],
  imports: [
    BrowserModule,
    PagesModules,
    APP_ROUTES,
    FormsModule,
    ServiceModule,
    ReactiveFormsModule,
    HttpClientModule,
    
  ],
  providers: [JwtHelperService, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }],
  bootstrap: [AppComponent]
})
export class AppModule { }
