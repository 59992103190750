import { Component,  OnInit } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit {
 
  dataTable: any; 
  constructor() { }

  ngOnInit() {
  }

}
