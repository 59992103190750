import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import  Swal  from 'sweetalert2';

// Forms
import { CrearCosteForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearEstadoForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearInstalacionForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearFactoriaForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearTallerForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearRolForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearTipoWtsForm } from '../interfaces/tablasMaestrasForms.interface';
import { CrearClientesAlbaran} from '../interfaces/tablasMaestrasForms.interface';

@Injectable({
  providedIn: 'root'
})
export class TablasMaestrasService {

  constructor(private http:HttpClient) { }

  // Codigo factorizado
  leer(tabla:string) {
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/' + tabla;
    return this.http.get(url, {headers:headers}).pipe(map(res=>res));    
  }  

  crearCoste (formData: CrearCosteForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/coste';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearEstado (formData: CrearEstadoForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/estadoReparacion';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearInstalacion (formData: CrearInstalacionForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/instalacion';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearFactoria (formData: CrearFactoriaForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/factoria';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearTaller (formData: CrearTallerForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/taller';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearRol (formData: CrearRolForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/rol';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }

  crearTipoWts (formData: CrearTipoWtsForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/tiposWts';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}));
  }  

  CrearClientesAlbaran(formData: CrearClientesAlbaran){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/clientesAlbaran';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((res:any) =>{}))
  }
/*
  // guardarCliente(formData: guardarCliente){
  //   let url = URL_SERVICIOS + '/clientesAlbaran';
  //   return this.http.post(url).pipe(map((res: any) =>{}))      
  //   }
  */

  eliminar(tabla:string, id:string, masc:boolean) {
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/' + tabla + '/' + id;
    return this.http.delete(url, {headers:headers}).pipe(map((res =>
      {//console.log(res);
        if (masc) {
        Swal.fire({
          title: tabla.charAt(0).toUpperCase() + tabla.slice(1) + ' borrado',
          text: 'Eliminado correctamente',
          icon: 'success'
        })
      } else {
        Swal.fire({
          title: tabla.charAt(0).toUpperCase() + tabla.slice(1) + ' borrada',
          text: 'Eliminada correctamente',
          icon: 'success'
        })
      }}
      )));
    }

}
