import { environment } from '../../environments/environment';
import { URL_SERVICIOS } from '../config/config';



export class Usuario {
    constructor(
        public idUsuario: number,
        public nombreUsuario: string,
        public email: string,
        public password: string,
        public idCliente: number,
        public DescripcionCliente: string,
        public role: string,
        public img?: string,
        public activo?: boolean,
        public FechaCreacion?: Date,
        public FechaModificacion?: Date
    ){}

    get imagenUrl(){        
        if (this.img.includes('https')) {
            return this.img;
        }
        
        if (this.img){
            return `${URL_SERVICIOS}/upload/usuarios/${this.img}`;
        }else {
            return `${URL_SERVICIOS}/upload/usuarios/no-image`;
        }        
    }    
}