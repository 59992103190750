import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/service.index';
import { UsuarioService } from '../../services/usuario.service';
import decode from 'jwt-decode';
import { Usuario } from '../../models/usuario.model';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  public imgUrl = '';
  public usuario: Usuario;

  role(){
    var token = localStorage.getItem('token');
    var tokenPayload = decode(token);  
    var roleUsuario = tokenPayload.usuario.role;     
    if (roleUsuario != 'ADMINISTRADOR'){
      return false;           
    }else{
      return true;  
  }
}
  public mostrarMenu = false;

  constructor( public sidebarService: SidebarService, usuarioService: UsuarioService) {
    this.imgUrl = usuarioService.usuario.imagenUrl;
    this.usuario = usuarioService.usuario;
  }

  ngOnInit() {     
    this.mostrarMenu = this.role();    
  }
}