import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estado-reparaciones-int',
  templateUrl: './estado-reparaciones-int.component.html',
  styleUrls: ['./estado-reparaciones-int.component.css'],
})
export class EstadoReparacionesIntComponent implements OnInit {

  constructor() { this.loadScripts(); }

  ngOnInit() {
    document.getElementById("titulo_Pagina").innerHTML = "Reparaciones en Curso";
  }

  loadScripts() {
    const externalScriptArray = [
      

      '../../../assets/plugins/cdn_datatables/dataTables.select.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.buttons.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.bootstrap4.min.js',
      '../../../assets/plugins/cloudflare/jszip.min.js',
      '../../../assets/plugins/cloudflare/pdfmake.min.js',
      '../../../assets/plugins/cloudflare/vfs_fonts.js',
      '../../../assets/plugins/cdn_datatables/buttons.html5.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.print.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.colVis.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.searchBuilder.min.js',
      '../../../assets/plugins/cdn_datatables/searchBuilder.bootstrap4.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.bootstrap4.min.js', 
      '../../../assets/js/estado-reparaciones-int.js',
    ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}
