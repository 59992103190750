import { Component, OnInit } from '@angular/core';
import decode from 'jwt-decode';

@Component({
  selector: 'app-reparacionCliente',
  templateUrl: './reparacionCliente.component.html',
  styleUrls: ['./reparacionCliente.component.css'],

})
export class reparacionClienteComponent implements OnInit {

 

  constructor() { this.loadScripts(); }

  ngOnInit() {
    document.getElementById("titulo_Pagina").innerHTML = "Reparaciones en Curso.";
  }
  
  loadScripts() {
    const externalScriptArray = [


      '../../../assets/plugins/cdn_datatables/dataTables.select.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.buttons.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.bootstrap4.min.js',
      '../../../assets/plugins/cloudflare/pdfmake.min.js',
      '../../../assets/plugins/cloudflare/vfs_fonts.js',
      '../../../assets/plugins/cdn_datatables/buttons.html5.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.print.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.colVis.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.searchBuilder.min.js',
      //'https://cdn.datatables.net/searchbuilder/1.0.1/js/searchBuilder.bootstrap4.min.js',
      '../../../assets/plugins/cdn_datatables/searchBuilder.bootstrap4.min.js',
   

      '../../../assets/plugins/Reparacioncliente.js'
      
    ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}

