import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrearUsuariosForm } from '../interfaces/crearUsuariosForm.interface';
import { URL_SERVICIOS } from '../config/config';
import { LoginForm } from '../interfaces/loginUsuariosFrom.interface';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  
  public usuario: Usuario;  
  public idUsuario:any;

  constructor( private http:HttpClient, public jwtHelper: JwtHelperService ) { }

  public ValidarToken(): boolean {
    const token = localStorage.getItem('token') || '';    
    return !this.jwtHelper.isTokenExpired(token);
  }  

  CrearUsuario (formData: CrearUsuariosForm){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/usuario';
    return this.http.post(url, formData, {headers:headers}).pipe(tap((resp:any) =>{localStorage.setItem('token',resp.token)}));
  }

  actualizarPerfil(data :{nombre:string, email:string, password:string}){
    var token = localStorage.getItem('token');
    var tokenPayload = decode(token);  
    var idUsuario = tokenPayload.usuario.idUsuario;          
    let url = URL_SERVICIOS + '/usuario/' + idUsuario ; 
    return this.http.put(url, data); 
  }

  loginUsuario (formData: LoginForm){
    let url = URL_SERVICIOS + '/login';
    return this.http.post(url, formData).pipe(tap((resp:any) =>{       
      const {DescripcionCliente, FechaModificacion, activo, email, factoria, idCliente, idUsuario, img, nombreUsuario, password, role } = resp.usuario;
      this.usuario = new Usuario(idUsuario, nombreUsuario, email, password, idCliente, DescripcionCliente, role, img );      
      localStorage.setItem('token',resp.token)
      let user=this.usuario.email
     
      //console.log(this.usuario);
      localStorage.setItem('Usuario', user);
    }
    ));
  }

  leerUsuarios(){
    let url = URL_SERVICIOS + '/usuario';
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    return this.http.get(url, {headers:headers}).pipe(map(res=>res));
  }

  leerFactoria(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/factoria';
    return this.http.get(url, {headers:headers}).pipe(map(res=>res));
  }

  leerRol(){
    let token: string = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({      
    'x-token': `${token}`
    });
    let url = URL_SERVICIOS + '/rol';
    return this.http.get(url, {headers:headers}).pipe(map(res=>res));
  }
}
