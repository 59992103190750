import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from '../../models/usuario.model';
import { FileUploadService } from '../../services/file-upload.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styles: []
})
export class PerfilComponent implements OnInit {

  public perfilForm:FormGroup;
  public usuario: Usuario;
  public imagenSubir: File;
  public imgUrl = '';
  public imgTmp:any = '';

  constructor(private fb:FormBuilder, private usuarioService: UsuarioService, private uploadService: FileUploadService) {
    this.usuario = usuarioService.usuario;
    this.imgUrl = usuarioService.usuario.imagenUrl;

  }

  ngOnInit() {
  this.perfilForm = this.fb.group({
    nombreUsuario:[this.usuario.nombreUsuario, Validators.required],
    email: [this.usuario.email, Validators.required],
    password:[this.usuario.password, Validators.required]
    });
  }
  actualizarPerfil(){    
    this.usuarioService.actualizarPerfil(this.perfilForm.value)
        .subscribe(resp=>{
          const {nombreUsuario, email, password} = this.perfilForm.value;
          this.usuario.nombreUsuario = nombreUsuario;
          this.usuario.email = email;
          this.usuario.password = password;          

        Swal.fire('Guardado', 'Perfil guardado correctamente', 'success');

        })
  }
  
  cambiarImagen( file: File ){
  this.imagenSubir = file;

  if(!file){return;}
  const reader = new FileReader();
  const url64 = reader.readAsDataURL(file);

  reader.onloadend=()=>{
    this.imgTmp = reader.result;
  }

  }

  subirImagen(){
    this.uploadService.actualizarFoto(this.imagenSubir, 'usuarios', this.usuario.idUsuario)
    .then(img => {
      this.usuario.img = img;
      Swal.fire('Guardado', 'Imagen de usuario actualizada', 'success');      
    });
  }
}
