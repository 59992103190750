import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms'
import { UsuarioService } from '../services/usuario.service';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:['../../assets/css/pages/login-register-lock.css']
})
export class LoginComponent implements OnInit {

  public formSubmitted = false;

  public loginForm = this.fb.group({    
    email: ['', Validators.required],
    password: ['', Validators.required]   
  });

  role(){
    var token = localStorage.getItem('token');
    var tokenPayload = decode(token);  
    var roleUsuario = tokenPayload.usuario.role;  
    // console.log(roleUsuario);   
    if (roleUsuario != 'ADMINISTRADOR'){
      return false;           
    }else{
      return true;  
  }
}
public tipoUsuario = false;

  constructor( private router:Router, private fb:FormBuilder, private UsuarioService: UsuarioService) { }

  ngOnInit() {    
    init_plugins();
  }

  ingresar(){    
    this.UsuarioService.loginUsuario(this.loginForm.value)
    .subscribe (resp =>{
      var tipoUsuario = this.role();        
      if(tipoUsuario != false){        
        window.location.href = '#/dashboard'       
    }else{      
        window.location.href = '#/dashboardClient'
      };      
    },(err)=>{
      Swal.fire('Error', err.error.message, 'error');  
    });        
  }  
}


