import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-albaranPDF',
  templateUrl: './AlbaranPDF.component.html',
  styleUrls: ['./AlbaranPDF.component.css'
  ],
  
})
export class albaranPDFComponent implements OnInit {
 
  constructor() { this.loadScripts();}
   
 
    ngOnInit() {
      document.getElementById("titulo_Pagina").innerHTML = "AlbaranPDF";
    }
    loadScripts() {
      const externalScriptArray = [
        
        '../../../assets/js/Chart.js',
        '../../../assets/js/jquery-1.12.4.min.js',
        '../../../assets/js/jspdf.min.js',
        '../../../assets/js/albaranpdf.js'
        
      ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
    }
  
}