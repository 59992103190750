import { Component, OnInit, OnDestroy } from '@angular/core';
declare const mainTable :any
@Component({
  selector: 'app-agendaexpediciones',
  templateUrl: './agendaexpediciones.component.html',
  styleUrls: ['./agendaexpediciones.component.css'
  ],
  
})
export class agendaexpedicionesComponent implements OnInit {
 
  
    constructor() { this.loadScripts();}
 
    ngOnInit() {
      document.getElementById("titulo_Pagina").innerHTML = "Agenda Para Expediciones";
    }
    loadScripts(){
      const externalScriptArray = [


        '../../../assets/plugins/datatables/dataTables.fixedColumns.js',
        '../../../assets/plugins/cdn_datatables/dataTables.buttons.min.js',
        '../../../assets/plugins/cdn_datatables/buttons.bootstrap4.min.js',
        '../../../assets/plugins/cloudflare/jszip.min.js',
        '../../../assets/plugins/cloudflare/pdfmake.min.js',
        '../../../assets/plugins/cloudflare/vfs_fonts.js',
        '../../../assets/plugins/cdn_datatables/buttons.html5.min.js',
        '../../../assets/plugins/cdn_datatables/buttons.print.min.js',
        '../../../assets/plugins/cdn_datatables/dataTables.searchBuilder.min.js',
        '../../../assets/plugins/cdn_datatables/searchBuilder.bootstrap4.min.js',
        '../../../assets/plugins/cdn_datatables/dataTables.bootstrap4.min.js', 
        '../../../assets/js/agendaexpediciones.js',      
        ]
      for (let i = 0; i < externalScriptArray.length; i++) {
        const scriptTag = document.createElement('script');
        scriptTag.src = externalScriptArray[i];
        scriptTag.type = 'text/javascript';
        scriptTag.async = false;
        scriptTag.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }
    }
}