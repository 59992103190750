import { Component, OnInit } from '@angular/core';
import { DatosWtsService } from '../../services/wts/datos-wts.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { datosWts } from '../../models/datosWts.model'

@Component({
  selector: 'app-hist-rep-cliente',
  templateUrl: './hist-rep-cliente.component.html',
  styleUrls: ['hist-rep-cliente.component.css']
})
export class HistRepClienteComponent implements OnInit {

  public reparacion : datosWts;
  

  constructor() { 
    this.loadScripts(); 
  }

  ngOnInit() {
    document.getElementById("titulo_Pagina").innerHTML = "Historial Reparaciones";

    // this.tiposWtsReparacion.cargarImagenesReparaciones().subscribe(res => {
    //   const img1 = res['data'].map(res => res.img1)
    //   console.log(img1);
    // })
  }

  // get img164(): SafeUrl {
  //   return this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${this.reparacion.img1}`);
  // }

  loadScripts() {
    const externalScriptArray = [

      '../../../assets/plugins/cdn_datatables/dataTables.select.min.js',
      '../../../assets/plugins/cdn_datatables/dataTables.buttons.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.bootstrap4.min.js',
      '../../../assets/plugins/cloudflare/jszip.min.js',
      '../../../assets/plugins/cloudflare/pdfmake.min.js',
      '../../../assets/plugins/cloudflare/vfs_fonts.js',
      '../../../assets/plugins/cdn_datatables/buttons.html5.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.print.min.js',
      '../../../assets/plugins/cdn_datatables/buttons.colVis.min.js',
      '../../../assets/js/hist-rep-cliente.js'
    ]
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }

}
