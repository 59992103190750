import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivateChild {
  
  constructor(private UsuarioService:UsuarioService, public router: Router){}

  canActivate(): boolean {
      if(!this.UsuarioService.ValidarToken()){
        this.router.navigate(['/login']);
      }    
    return true;
  }  
  canActivateChild(): boolean {
    if(!this.UsuarioService.ValidarToken()){
      this.router.navigate(['/login']);
    }  
  return true;
}  
}
